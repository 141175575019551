<template>
	<div>
		<div class="flex justify-end">
			<!-- 操作按钮 -->
			<el-form :inline="true">
				<el-form-item>
					<el-button type="primary" icon="el-icon-plus" @click="toEdit()">添加{{typeName}}分类</el-button>
				</el-form-item>
			</el-form>
		</div>
		<el-table :data="tableDataSelf" v-loading="loading" max-height="9999">
			<el-table-column prop="sort" label="排序" width="180">
			</el-table-column>
			<el-table-column prop="name" label="分类名称">
			</el-table-column>
			<el-table-column prop="is_show" label="是否显示" width="180">
				<template slot-scope="scope">
					<el-switch :value="scope.row.is_show" :active-value="1" :inactive-value="0" @change="selfChange(scope.row)"></el-switch>
				</template>
			</el-table-column>
			<el-table-column fixed="right" label="操作" width="150">
				<template slot-scope="scope">
					<el-button @click="toEdit(scope.row)" size="mini">编辑</el-button>
					<el-popconfirm title="确定删除吗？" @confirm="delClassify(scope.row)">
						<el-button slot="reference" size="mini">删除</el-button>
					</el-popconfirm>
				</template>
			</el-table-column>
		</el-table>
		<!-- 编辑分类 -->
		<el-dialog :title="classifyDialog.title" :visible.sync="classifyDialog.dialogVisible" width="500px" :append-to-body="true"
		:close-on-click-modal="false">
			<div>
				<el-form ref="form" :model="classify" :rules="rules" label-width="80px">
					<el-form-item label="名称" prop="name" :rules="rules.required">
						<el-input v-model="classify.name"></el-input>
					</el-form-item>
					<el-form-item label="排序" prop="sort" :rules="rules.required">
						<el-input-number v-model="classify.sort" :precision="0" :min="0" :step="1" :max="99999"></el-input-number>
					</el-form-item>
					<el-form-item label="是否显示" prop="amount">
						<el-switch v-model="classify.is_show" :active-value="1" :inactive-value="0"></el-switch>
					</el-form-item>
				</el-form>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button @click="classifyDialog.dialogVisible = false">取 消</el-button>
				<el-button type="primary" @click="saveClassify()" :loading="btnLoading">确 定</el-button>
			</span>
		</el-dialog>

	</div>
</template>
<script>
	// 引入公用表单验证
	import rules from "../../../common/common.rules";
	export default {
		data() {
			return {
				rules,
				// 弹框
				classifyDialog: {
					title: undefined,
					dialogVisible: false
				},
				// 添加、编辑分类
				classify: {
					id: undefined,
					sort: undefined,
					name: undefined,
					is_show: 1
				},
				// 当前页面的分类类型（服务）
				type: undefined,
				loading: false, // 表格加载状态
				tableDataSelf: [], // 自营分类数据
				btnLoading: false, // 按钮加载状态
			};
		},
		watch: {
			$route(newVal) {
				console.log(newVal)
				this.loadClassifySelf()
			}
		},
		computed: {
			typeName() {
				if (this.$route.params.type == 'goods') {
					return '商品'
				} else if (this.$route.params.type == 'project') {
					return '项目'
				}
				return '';
			}
		},
		mounted() {
			this.loadClassifySelf()
		},
		methods: {
			loadClassifySelf() {
				if (this.$route.params.type == 'goods') {
					this.type = this.$api.classify.GOODS;
				} else if (this.$route.params.type == 'project') {
					this.type = this.$api.classify.SERVICE;
				}
				this.loading = true;
				this.$api.classify.getClassify(this.type).then(res => {
					this.tableDataSelf = res.data
					this.loading = false
				})
			},
			selfChange(item) {
				let is_show = item.is_show;
				item.is_show = item.is_show == 1 ? 0 : 1;
				this.$api.classify.save(this.type, item).then(res => {
					if (res.code == 200) {
						this.$message.success('操作成功')
					} else {
						this.$message.error(res.msg)
						item.is_show = is_show;
					}
				}).catch(err => {
					item.is_show = is_show;
					this.$alert(err)
				})
			},
			toEdit(item) {
				if (item) {
					this.classifyDialog.title = "编辑分类"
					this.classify = item
				} else {
					this.classifyDialog.title = "新增分类"
					this.classify.id = undefined
					this.classify.sort = 1
					this.classify.name = undefined
					this.classify.is_show = 1
				}
				this.classifyDialog.dialogVisible = true;
			},
			saveClassify() {
				this.$refs.form.validate(valid => {
					if (valid) {
						this.btnLoading = true;
						this.$api.classify.save(this.type, this.classify).then(res => {
							this.btnLoading = false;
							if (res.code == 200) {
								// 保存成功
								this.$message.success(`保存成功`);
								this.classifyDialog.dialogVisible = false;
								this.loadClassifySelf()
							} else {
								this.$message.error(res.msg);
							}
						}).catch(err => {
							this.btnLoading = false;
							this.$alert(err);
						});
					} else {
						this.$message.warning(`请检查输入错误`);
						return false;
					}
				});
			},
			delClassify(item) {
				const loading = this.$loading()
				this.$api.classify.del(this.type, {
					id: item.id
				}).then(res => {
					loading.close()
					if (res.code == 200) {
						// 保存成功
						this.$message.success(`保存成功`);
						this.classifyDialog.dialogVisible = false;
						this.loadClassifySelf()
					} else {
						this.$message.error(res.msg);
					}
				}).catch(err => {
					loading.close()
					this.$alert(err);
				});
			}
		},
	};
</script>

<style scoped lang="scss">

</style>
