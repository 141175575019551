<template>
	<div class="wrap">
		<el-tabs v-model="activeName">
			<el-tab-pane name="second">
				<span slot="label">
					<i class="el-icon-notebook-2"></i> 自营分类
				</span>
				<el-alert title="操作提示" type="success" class="margin-bottom">
					<div>
						1. 该页面展示了小店所有的分类信息，可对分类进行编辑修改操作。<br />
						2. 商家添加的自营分类，不会出现在小程序端，能提供线下收银端使用。
					</div>
				</el-alert>
				<my-self></my-self>
			</el-tab-pane>
			<el-tab-pane name="first">
				<span slot="label">
					<i class="el-icon-notebook-2"></i> 品牌分类
				</span>
				<el-alert title="操作提示" type="success" class="margin-bottom">
					<div>
						1. 该页面展示了小店所有的项目信息，可对项目进行编辑修改操作。<br />
						2. 商家添加的自营项目，不会出现在小程序端，能提供线下收银端使用。
					</div>
				</el-alert>
				<my-brand></my-brand>
			</el-tab-pane>
			
		</el-tabs>
	</div>
</template>
<script>
	// 引入公用表单验证
	import rules from "../../common/common.rules";
	import MySelf from "./children/MySelf.vue";
	import MyBrand from "./children/MyBrand.vue";
	export default {
		components: {
			MySelf,
			MyBrand
		},
		data() {
			return {
				rules,
				// 弹框
				classifyDialog: {
					title: undefined,
					dialogVisible: false
				},
				// 添加、编辑分类
				classify: {
					id: undefined,
					sort: undefined,
					name: undefined,
					is_show: 1
				},
				// 当前页面的分类类型（服务）
				type: undefined,
				// 当前显示的tab
				activeName: 'second',
				loading: false, // 表格加载状态
				tableDataSelf: [], // 自营分类数据
				btnLoading: false, // 按钮加载状态
			};
		},
		watch: {
			$route(newVal) {
				console.log(newVal)
				this.loadClassifySelf()
			}
		},
		computed: {
			typeName() {
				if (this.$route.params.type == 'goods') {
					return '商品'
				} else if (this.$route.params.type == 'project') {
					return '项目'
				}
				return '';
			}
		},
		mounted() {
			this.loadClassifySelf()
		},
		methods: {
			loadClassifySelf() {
				if (this.$route.params.type == 'goods') {
					this.type = this.$api.classify.GOODS;
				} else if (this.$route.params.type == 'project') {
					this.type = this.$api.classify.SERVICE;
				}
				this.loading = true;
				this.$api.classify.getClassify(this.type).then(res => {
					this.tableDataSelf = res.data
					this.loading = false
				})
			},
			selfChange(item) {
				let is_show = item.is_show;
				item.is_show = item.is_show == 1 ? 0 : 1;
				this.$api.classify.save(this.type, item).then(res => {
					if (res.code == 200) {
						this.$message.success('操作成功')
					} else {
						this.$message.error(res.msg)
						item.is_show = is_show;
					}
				}).catch(err => {
					item.is_show = is_show;
					this.$alert(err)
				})
			},
			toEdit(item) {
				if (item) {
					this.classifyDialog.title = "编辑分类"
					this.classify = item
				} else {
					this.classifyDialog.title = "新增分类"
					this.classify.id = undefined
					this.classify.sort = 1
					this.classify.name = undefined
					this.classify.is_show = 1
				}
				this.classifyDialog.dialogVisible = true;
			},
			saveClassify() {
				this.$refs.form.validate(valid => {
					if (valid) {
						this.btnLoading = true;
						this.$api.classify.save(this.type, this.classify).then(res => {
							this.btnLoading = false;
							if (res.code == 200) {
								// 保存成功
								this.$message.success(`保存成功`);
								this.classifyDialog.dialogVisible = false;
								this.loadClassifySelf()
							} else {
								this.$message.error(res.msg);
							}
						}).catch(err => {
							this.btnLoading = false;
							this.$alert(err);
						});
					} else {
						this.$message.warning(`请检查输入错误`);
						return false;
					}
				});
			},
			delClassify(item) {
				const loading = this.$loading()
				this.$api.classify.del(this.type, {
					id: item.id
				}).then(res => {
					loading.close()
					if (res.code == 200) {
						// 保存成功
						this.$message.success(`保存成功`);
						this.classifyDialog.dialogVisible = false;
						this.loadClassifySelf()
					} else {
						this.$message.error(res.msg);
					}
				}).catch(err => {
					loading.close()
					this.$alert(err);
				});
			}
		},
	};
</script>

<style scoped lang="scss">

</style>
