<template>
	<div>
		<el-table :data="tableDataBrand" v-loading="loading" max-height="9999">
			<el-table-column prop="name" label="分类名称" width="180">
			</el-table-column>
			<el-table-column prop="children" label="二级分类名称">
				<template slot-scope="scope">
					<div v-for="item in scope.row.children" :key="item.id">
						{{item.name}}
					</div>
				</template>
			</el-table-column>
			<!-- <el-table-column prop="is_show" label="是否显示" v-if="typeName=='项目'">
				<template slot-scope="scope">
					<el-switch :value="scope.row.is_show" :active-value="1" :inactive-value="0" @change="showChange(scope.row)"></el-switch>
				</template>
			</el-table-column> -->
		</el-table>
	</div>
</template>
<script>
	export default {
		data() {
			return {
				// 当前页面的分类类型（服务）
				type: undefined,
				loading: false, // 表格加载状态
				tableDataBrand: [], // 自营分类数据
			};
		},
		watch: {
			$route(newVal) {
				console.log(newVal)
				this.loadClassify()
			}
		},
		computed: {
			typeName() {
				if (this.$route.params.type == 'goods') {
					return '商品'
				} else if (this.$route.params.type == 'project') {
					return '项目'
				}
				return '';
			}
		},
		mounted() {
			this.loadClassify()
		},
		methods: {
			loadClassify() {
				if (this.$route.params.type == 'goods') {
					this.type = this.$api.classify.GOODS;
				} else if (this.$route.params.type == 'project') {
					this.type = this.$api.classify.SERVICE;
				}
				this.loading = true;
				this.$api.classify.getBrandClassify(this.type).then(res => {
					this.tableDataBrand = res.data
					this.loading = false
				})
			},
			// 是否显示
			showChange(item){
				let status = item.status;
				item.status = item.status == 1 ? 0 : 1;
				this.$api.classify.setBrandClassifyShow({
					id: item.id
				}).then(res => {
					if (res.code == 200) {
						this.$message.success('操作成功')
					} else {
						this.$message.error(res.msg)
						item.status = status;
					}
				}).catch(err => {
					item.status = status;
					this.$alert(err)
				})
			}
		},
	};
</script>

<style scoped lang="scss">

</style>
